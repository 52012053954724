<template>
    <component
        :is="buttonType"
        :to="buttonType === 'router-link' ? linkTo : null"
        :href="buttonType === 'a' ? linkTo : null"
        class="btn"
        :class="[buttonClass, { disabled: isDisabled }]"
        :disabled="(buttonType === 'button' && isDisabled) || isLoading"
        :type="buttonType === 'button' ? buttonSubmitType : null"
        @click="handleClick"
        v-bind="additionalProps"
    >
        <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
        ></span>
        {{ isLoading ? loadingText : buttonText }}
    </component>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        buttonType: {
            type: String,
            default: 'button', // Possible values: 'button', 'router-link', 'a'
            validator: (value) => ['button', 'router-link', 'a'].includes(value),
        },
        buttonClass: {
            type: String,
            default: 'btn-primary',
        },
        buttonText: {
            type: String,
            default: 'Click me',
        },
        loadingText: {
            type: String,
            default: 'Loading...',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        linkTo: {
            type: String,
            default: '#',
        },
        buttonSubmitType: {
            type: String,
            default: 'button', // Possible values: 'button', 'submit', 'reset'
        },
        additionalProps: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        handleClick(event) {
            // Emit a custom click event if needed
            this.$emit('click', event);
        },
    },
};
</script>

<style scoped>
.disabled {
    pointer-events: none;
    opacity: 0.65;
}
</style>
