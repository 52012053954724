<template>
    <div class="d-flex justify-content-between align-items-center">
        <!-- Affichage "Page X sur Y" à gauche -->
        <div v-if="totalPages >= 1">
            {{ $t('pagination.page') }} {{ currentPage }} {{ $t('pagination.of') }} {{ totalPages }}
        </div>

        <!-- Pagination à droite -->
        <nav aria-label="Pagination">
            <ul class="pagination justify-content-end mb-0" v-if="totalPages >= 1">
                <!-- Bouton "Précédent" -->
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">
                        &laquo; {{ previousLabel }}
                    </button>
                </li>

                <!-- Numéros des pages -->
                <li v-for="page in pages" :key="page" class="page-item" :class="{ active: page === currentPage }">
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>

                <!-- Bouton "Suivant" -->
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">
                        {{ nextLabel }} &raquo;
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        previousLabel: {
            type: String,
            default: "Previous",
        },
        nextLabel: {
            type: String,
            default: "Next",
        },
        maxVisiblePages: {
            type: Number,
            default: 5, // Nombre maximum de pages visibles
        },
    },
    computed: {
        pages() {
            const half = Math.floor(this.maxVisiblePages / 2);
            let start = Math.max(this.currentPage - half, 1);
            let end = Math.min(start + this.maxVisiblePages - 1, this.totalPages);

            if (end - start + 1 < this.maxVisiblePages) {
                start = Math.max(end - this.maxVisiblePages + 1, 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        },
    },
    methods: {
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.$emit("page-changed", page);
            }
        },
    },
};
</script>