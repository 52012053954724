<template>
    <div class="container mt-3">
        <div class="row mb-3 align-items-center">
            <!-- Sélection du nombre d'éléments par page -->
            <div class="col-md-2">
                <label>{{ $t('general.print') }}</label>
                <select v-model="perPage" @change="reloadTable" class="form-select">
                    <option v-for="n in perPageOptions" :key="n" :value="n">{{ n }}</option>
                </select>
            </div>

            <!-- Recherche -->
            <div class="col-md-6" v-if="isSearchable">
                <label>{{ $t('general.search') }}</label>
                <input type="text" v-model="searchQuery" @input="reloadTable" placeholder="Rechercher..." class="form-control" />
            </div>

            <div v-if="(selectedRows.length > 0) && (multipleActions.length > 0)" class="col-md-4 mt-4">
                <button :class="'me-1 btn btn-' + action.color" v-for="action in multipleActions" :key="action.type" @click="action.function(selectedRows)">
                    {{ action.title }}
                </button>
            </div>

            <!-- Filtres dynamiques -->
            <template v-if="filters.length > 0">
                <div v-for="(filter, index) in filters" :key="filter.key" class="col-md-4" :class="{ 'mt-2': filters.length >= 2 && index > 0 }">
                    <label>{{ filter.label }}</label>
                    <template v-if="filter.type === 'select'">
                        <select v-model="selectedFilters[filter.key]" @change="reloadTable" class="form-select">
                            <option :value="filter.default || ''">{{ $t('general.all') }}</option>
                            <option v-for="item in filter.options" :key="item.id" :value="item.id">{{ item.name }}</option>
                        </select>
                    </template>
                    <template v-else-if="filter.type === 'text'">
                        <input type="text" v-model="selectedFilters[filter.key]" @input="reloadTable" class="form-control" :placeholder="filter.placeholder || ''" />
                    </template>
                    <template v-else-if="filter.type === 'date'">
                        <input type="date" v-model="selectedFilters[filter.key]" @input="reloadTable" class="form-control" />
                    </template>
                </div>
            </template>
        </div>
        <hr />

        <!-- Table -->
        <table class="table my-datatables-reponsive">
            <thead>
                <tr>
                    <!-- Checkbox de sélection globale -->
                    <th v-if="multipleActions && multipleActions.length > 0">
                        <input class="form-check-input" type="checkbox" @change="toggleSelectAll($event)" :checked="data?.length === selectedRows?.length" />
                    </th>
                    <th v-for="header in headers" :key="header.name">{{ header.name }}</th>
                </tr>
            </thead>
            <tbody v-if="!isLoading">
                <tr v-for="(row, index) in data" :key="index">
                    <td v-if="multipleActions && multipleActions.length > 0">
                        <input class="form-check-input" type="checkbox" :id="row?.id" @change="updateSelectedRows(row?.id)" :checked="selectedRows.includes(row?.id)" />
                    </td>
                    <td v-for="header in headers" :key="header.key">
                        <template v-if="header.type === 'action'">
                            <span v-for="action in header.actions" :key="action.type">
                                <button
                                    v-if="!isModalType(action.type) && action.check_permission && action.check_permission()"
                                    :class="['btn btn-sm me-1', getActionClass(action.type)]"
                                    @click="action.function(row)">
                                    <template v-if="getIcon(action.type)">
                                        <i :class="getIcon(action.type)" class="me-1"></i>
                                    </template>
                                    <template v-if="action.show_text">
                                        {{ action.text }}
                                    </template>
                                </button>
                                <button
                                    v-else-if="isModalType(action.type) && action.check_permission && action.check_permission()"
                                    data-bs-toggle="modal" :data-bs-target="action.target"
                                    :class="['btn btn-sm me-1', getActionClass(action.type)]"
                                    @click="action.function(row)">
                                    <template v-if="getIcon(action.type)">
                                        <i :class="getIcon(action.type)" class="me-1"></i>
                                    </template>
                                    <template v-if="action.show_text">
                                        {{ action.text }}
                                    </template>
                                </button>
                            </span>
                        </template>
                        <template v-else-if="header.badge">
                            <span :class="'badge ' + header.badge || 'bg-primary'">
                                {{ header?.format ? header?.format(getNestedValue(row, header.key)) || '-' : getNestedValue(row, header.key) || '-' }}
                            </span>
                        </template>
                        <template v-else>
                            {{ header?.format ? header?.format(getNestedValue(row, header.key)) || '-' : getNestedValue(row, header.key) || '-' }}
                        </template>
                    </td>
                </tr>
                <tr v-if="data.length === 0">
                    <td :colspan="headers.length + 1" class="text-center">Aucune donnée disponible</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td :colspan="headers.length + 1" class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Chargement...</span>
                        </div>
                        <p>{{ $t('general.loading') }}...</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination -->
        <div class="d-flex justify-content-between align-items-center" v-if="paginable && !isLoading">
            <span>Page {{ currentPage }} sur {{ totalPage }}</span>
            <ul class="pagination pagination-sm mb-0">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="changePage(currentPage - 1)">{{ $t('pagination.previous') }}</button>
                </li>
                <li v-for="page in totalPage" :key="page" class="page-item" :class="{ active: page === currentPage }">
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPage }">
                    <button class="page-link" @click="changePage(currentPage + 1)">{{ $t('pagination.next') }}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from "vue";

const props = defineProps({
    headers: Array,
    data: Array,
    filters: Array,
    paginable: Boolean,
    totalPage: Number,
    currentPage: Number,
    isSearchable: Boolean,
    reloadFunction: Function,
    isLoading: {
        type: Boolean,
        default: false,
    },
    multipleActions: Array,
});

const perPageOptions = [2, 5, 10, 25, 50, 100];
const perPage = ref(10);
const searchQuery = ref("");
const selectedFilters = ref({});
const currentPage = ref(props.currentPage || 1);
const selectedRows = ref([]);

const reloadTable = () => {
    props.reloadFunction({
        search: searchQuery.value,
        filters: selectedFilters.value,
        perPage: perPage.value,
        page: currentPage.value,
    });
};

const toggleSelectAll = (event) => {
    if (event.target.checked) {
        selectedRows.value = props.data?.map((row) => row?.id);
    } else {
        selectedRows.value = [];
    }
};

const updateSelectedRows = (rowId) => {
    if (selectedRows.value.includes(rowId)) {
        selectedRows.value = selectedRows.value.filter((id) => id !== rowId);
    } else {
        selectedRows.value.push(rowId);
    }
}

const getIcon = (actionType) => {
    const icons = {
        view: "fas fa-eye",
        edit: "fas fa-edit",
        delete: "fas fa-trash",
        modal_view: "fas fa-eye",
        modal_edit: "fas fa-edit",
        modal_delete: "fas fa-trash",
        other: "",
    };
    return icons[actionType] || null;
};

const isModalType = (type) => {
    return ["modal_edit", "modal_delete", "modal_view"].includes(type);
};

const getActionClass = (type) => {
    switch (type) {
        case "edit":
            return "btn-warning";
        case "view":
            return "btn-primary";
        case "delete":
            return "btn-danger";
        case "modal_edit":
            return "btn-warning";
        case "modal_delete":
            return "btn-danger";
        case "modal_view":
            return "btn-primary";
        default:
            return "btn-secondary";
    }
};

const initializeFilters = () => {
    selectedFilters.value = props.filters.reduce((acc, filter) => {
        acc[filter.key] = filter.default || ""; // Applique la valeur par défaut si elle existe
        return acc;
    }, {});
};

// Helpers
const getNestedValue = (obj, key) => key.split(".").reduce((o, k) => (o || {})[k], obj);

const changePage = (page) => {
    if (page >= 1 && page <= props.totalPage) {
        currentPage.value = page;
        reloadTable();
    }
};

watch(
    () => props.currentPage,
    (newPage) => {
        currentPage.value = newPage;
    }
);

onMounted(() => {
    initializeFilters();
});
</script>
