<template>
  <div>
    <div v-if="isDispo">
      <div class="container-fluid p-0" v-if="getCurrentUser?.user_type?.name == 'Head'">
        <HeadPageComponent :type="getCurrentUser?.user_type?.name"></HeadPageComponent>
      </div>
      <div class="container-fluid p-0" v-else-if="getCurrentUser?.user_type?.name == 'Partener'">
        <PartenerPageComponent :type="getCurrentUser?.user_type?.name"></PartenerPageComponent>
      </div>
      <div class="container-fluid p-0" v-else-if="getCurrentUser?.user_type?.name == 'Supervisor'">
        <SupervisorPageComponent :type="getCurrentUser?.user_type?.name"></SupervisorPageComponent>
      </div>
      <div class="container-fluid p-0" v-else>
        <UserPageComponent :type="getCurrentUser?.user_type?.name"></UserPageComponent>
      </div>
    </div>
    <div v-else class="loader">
            <span>{{ $t('evaluation.chargement') }}...</span>
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import HeadPageComponent from '@/components/AuthPageComponent/HeadPageComponent.vue'
import UserPageComponent from '@/components/AuthPageComponent/UserPageComponent.vue'
import PartenerPageComponent from '@/components/AuthPageComponent/PartenerPageComponent.vue'
import SupervisorPageComponent from '@/components/AuthPageComponent/SupervisorPageComponent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  data() {
    return {
      isDispo: false,
    }
  },
  components: {
    HeadPageComponent,
    UserPageComponent,
    SupervisorPageComponent,
    PartenerPageComponent
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
  },
  methods: {
    ...mapActions('auth', ['_currentUser']),
  },
  async mounted() {
    this.isDispo = false
    if (!this.getCurrentUser) {
      await this._currentUser()
    }
    this.isDispo = true
  },
}
</script>

<style scoped>
.badge {
  display: none !important;
  align-items: center;
}

.badge-light-danger {
  color: #f1416c;
  background-color: #fff5f8;
  padding: 15px !important;
  text-align: center !important;
}
</style>