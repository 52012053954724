<template>
  <div v-if="isLoading">
    <empty-page-component code="Warning" :message="$t('general.loading') + '...'"></empty-page-component>
  </div>
  <div v-else class="container-fluid p-0">
    <h3><strong>{{ $t('page.tickets.tickets') }}</strong></h3>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" data-bs-toggle="tab" href="#requests" role="tab">{{ $t('page.tickets.my_requests') }}</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="tab" href="#create" role="tab">{{ $t('page.tickets.new_ticket') }}</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="tab" href="#assigned" role="tab">{{ $t('page.tickets.my_tickets') }}</a>
          </li>
          <li v-if="getCurrentUser.user_type.name === 'Head'" class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="tab" href="#all" role="tab">{{ $t('page.tickets.all') }}</a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div v-if="getCurrentUser.user_type.name === 'Head'" class="tab-pane fade" id="all" role="tabpanel">
            <div class="mb-3">
              <input v-model="search" type="text" class="form-control" placeholder="Search tickets...">
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('page.tickets.name') }}</th>
                  <th>{{ $t('page.tickets.status') }}</th>
                  <th>{{ $t('page.tickets.criticality') }}</th>
                  <th>{{ $t('page.tickets.createdby') }}</th>
                  <th>{{ $t('page.tickets.assignedto') }}</th>
                  <th>{{ $t('page.tickets.createdat') }}</th>
                  <th>{{ $t('page.tickets.due_date') }} | {{ $t('page.tickets.end_date') }}</th>
                  <th>{{ $t('page.tickets.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in allTickets" :key="ticket.id">
                  <td>{{ ticket.title }}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="badge bg-success" v-if="ticket.status === 'Completed'">{{ ticket.status }}</span>
                      <span class="badge bg-primary" v-if="ticket.status === 'Open'">{{ ticket.status }}</span>
                      <span class="badge bg-info" v-if="ticket.status === 'In Progress'">{{ ticket.status }} | <span>{{ calculateDelay(ticket) }} </span></span>
                    </div>
                  </td>
                  <td>
                    <span class="badge" :class="{
                      'bg-danger': ticket.criticality.name === 'High',
                      'bg-warning': ticket.criticality.name === 'Medium',
                      'bg-info': ticket.criticality.name === 'Low'
                    }">{{ ticket.criticality.name }}</span>
                  </td>
                  <td>{{ ticket.created_by.name }}</td>
                  <td>
                    <span v-if="ticket.latest_assignment">
                      <span v-if="ticket.latest_assignment.assigned_to">
                        {{ ticket.latest_assignment.assigned_to.name }}
                      </span>
                      <span v-else-if="ticket.latest_assignment.assigned_group">
                        {{ ticket.latest_assignment.assigned_group.name }}
                      </span>
                    </span>
                    <span v-else>{{ $t('page.tickets.notassigned') }}</span>
                  </td>
                  <td>{{ formatDate(ticket.created_at) }}</td>
                  <td>{{ formatDate(ticket.resolution_deadline) }} | {{ formatDate(ticket.end_time) }}</td>
                  <td>
                    <router-link :to="{ name: 'ticket-detail', params: { ticket_id: ticket.ticket_id } }" class="btn btn-secondary me-1" title="View">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye align-middle btn-link">
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade" id="create" role="tabpanel">
            <form @submit.prevent="saveTicket">
              <div class="mb-3">
                <label for="title" class="form-label">{{ $t('page.tickets.name') }}<i
                  class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                <input v-model="newTicket.title" type="text" class="form-control" id="title" required>
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">{{ $t('general.description') }}}<i
                  class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                <textarea v-model="newTicket.description" class="form-control" id="description" rows="3" required></textarea>
              </div>
              <div class="mb-3">
                <label for="requestType" class="form-label">{{ $t('page.tickets.requesttype') }}<i
                  class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                <select @change="getServiceType" class="form-select" name="requestType" v-model="newTicket.request_type" id="validationCustom12" required>
                    <option value="">{{ $t('general.choice') }}</option>
                    <option v-for="(service, key) in services" :key="key" :value="service.id">{{ service.name }} ({{ service.cyclique_number }})</option>
                    <option value="autres">Autres</option>
                </select>
              </div>
              <div class="mb-3" v-if="newTicket.request_type === 'autres'">
                <label for="otherRequestType" class="form-label">{{ $t('page.tickets.otherrequest') }}<i
                  class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                <input v-model="newTicket.other_request_type" type="text" class="form-control" id="otherRequestType">
              </div>
              <div class="mb-3">
                <label for="criticality" class="form-label">{{ $t('page.tickets.criticality') }}<i
                  class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                <select v-model="newTicket.criticality" class="form-select" id="criticality" required>
                  <option value="">{{ $t('general.choice') }}</option>
                  <option v-for="crit in criticalities" :key="crit.id" :value="crit.id">{{ crit.name }} ({{ getDaysFromDuration(crit.max_resolution_time) }} {{ $t('new.days') }})</option>
                </select>
              </div>
              <CustomButton
                  buttonType="button"
                  buttonSubmitType="submit"
                  buttonClass="btn-primary"
                  :buttonText="$t('page.tickets.create')"
                  :isLoading="isLoading"
                  :loadingText="$t('general.loading') + '...'"
              />
            </form>
          </div>
          <div class="tab-pane fade" id="assigned" role="tabpanel">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('page.tickets.name') }}</th>
                  <th>{{ $t('page.tickets.status') }}</th>
                  <th>{{ $t('page.tickets.criticality') }}</th>
                  <th>{{ $t('page.tickets.due_date') }}</th>
                  <th>{{ $t('page.tickets.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in userTickets" :key="ticket.id">
                  <td>{{ ticket.title }}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="badge bg-success" v-if="ticket.status === 'Completed'">{{ ticket.status }}</span>
                      <span class="badge bg-danger" v-if="ticket.status === 'Open'">{{ ticket.status }}</span>
                      <span class="badge bg-warning" v-if="ticket.status === 'In Progress'">{{ ticket.status }} | <span>{{ calculateDelay(ticket) }} </span></span>
                    </div>
                  </td>
                  <td>
                    <span class="badge" :class="{
                      'bg-danger': ticket.criticality.name === 'High',
                      'bg-warning': ticket.criticality.name === 'Medium',
                      'bg-info': ticket.criticality.name === 'Low'
                    }">{{ ticket.criticality.name }}</span>
                  </td>
                  <td>{{ formatDate(ticket.resolution_deadline) }}</td>
                  <td class="d-none d-xl-table-cell text-end">
                    <router-link :to="{ name: 'ticket-detail', params: { ticket_id: ticket.ticket_id } }" class="btn btn-secondary me-1" title="View">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye align-middle btn-link">
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </router-link>
                    <span @click="startTicket(ticket.ticket_id)" class="btn btn-primary me-1" title="Start" v-show="ticket.status === 'Open'">{{ $t('page.tasks.start_task') }}</span>
                    <span @click="completeTicket(ticket.ticket_id)" title="Complete" class="me-1 btn btn-success" v-show="ticket.status === 'In Progress'">{{ $t('page.tasks.completed') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade show active" id="requests" role="tabpanel">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('page.tickets.name') }}</th>
                  <th>{{ $t('page.tickets.status') }}</th>
                  <th>{{ $t('page.tickets.criticality') }}</th>
                  <th>{{ $t('page.tickets.createdat') }}</th>
                  <th>{{ $t('page.tickets.due_date') }}</th>
                  <th>{{ $t('page.tickets.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in filteredTickets" :key="ticket.id">
                  <td>{{ ticket.title }}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="badge bg-success" v-if="ticket.status === 'Completed'">{{ ticket.status }}</span>
                      <span class="badge bg-danger" v-if="ticket.status === 'Open'">{{ ticket.status }}</span>
                      <span class="badge bg-warning" v-if="ticket.status === 'In Progress'">{{ ticket.status }} | <span>{{ calculateDelay(ticket) }} </span></span>
                    </div>
                  </td>
                  <td>
                    <span class="badge" :class="{
                      'bg-danger': ticket.criticality.name === 'High',
                      'bg-warning': ticket.criticality.name === 'Medium',
                      'bg-info': ticket.criticality.name === 'Low'
                    }">{{ ticket.criticality.name }}</span>
                  </td>
                  <td>{{ formatDate(ticket.created_at) }}</td>
                  <td>{{ formatDate(ticket.resolution_deadline) }}</td>
                  <td>
                    <router-link :to="{ name: 'ticket-detail', params: { ticket_id: ticket.ticket_id } }" class="btn btn-secondary me-1" title="View">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye align-middle btn-link">
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue';
import moment from 'moment';
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'Tickets',
  components: {
    EmptyPageComponent,
    CustomButton
  },
  data() {
    return {
      isLoading: true,
      Ticket: {
          title: '',
          description: '',
          request_type: null,
          other_request_type: null,
          service_type: null,
          created_by: null,
          status: this.$t('general.open'),
          criticality: null,
          resolution_deadline: null,
          started_time: null,
          end_time: null,
          open_date: null
      },
      newTicket: {
        title: '',
        description: '',
        request_type: null,
        other_request_type: null,
        criticality: null,
        resolution_deadline: null,
        started_time: new Date().toISOString().slice(0, 16)
      },
      search: '',
      allUsers: null,
      userTypes: [],
      services: [],
      criticalities: [],
      showAssignModal: false,
      selectedServiceType: null,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters('ticket', ['getTickets']),
    ...mapGetters('auth', ['getCurrentUser']),
    allTickets() {
      return this.getTickets;
    },
    filteredTickets() {
      return this.getTickets.filter(ticket => 
        ticket.created_by.id === this.getCurrentUser.id
      );
    },
    userTickets() {
      return this.getTickets.filter(ticket => 
        (ticket.latest_assignment && ticket.latest_assignment.assigned_to && ticket.latest_assignment.assigned_to.id === this.getCurrentUser.id) ||
        (ticket.latest_assignment && ticket.latest_assignment.assigned_group && ticket.latest_assignment.assigned_group.id === this.getCurrentUser.user_type.id)
      );
    },
  },
  methods: {
    ...mapActions('auth', ['users', '_currentUser', 'getUsersByUserTypeName', 'getCriticalities']),
    ...mapActions('ticket', ['fetchTickets', 'fetchTicket', 'createTicket', 'updateTicketStatus', 'updateTicket', 'assignTicketToUser', 'assignTicketToUserType', 'getUserTickets']),
    ...mapActions('user_type', ['fetchUserTypes']),
    ...mapActions('service', ['getServiceUnarchives']),
    async loadInitialData() {
      if(! this.getCurrentUser){
          await this._currentUser()
      }
      const tickets = await this.fetchTickets();
      this.allUsers = await this.users();
      this.userTypes = await this.fetchUserTypes();
      this.services = await this.getServiceUnarchives();
      this.criticalities = await this.getCriticalities();
      return tickets;
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    getDaysFromDuration(duration) {
    return duration.split(' ')[0];
  },
  async saveTicket() {
    this.isLoading = true
    this.newTicket.created_by = this.getCurrentUser.id;
    
    if (this.newTicket.request_type === 'autres') {
        this.newTicket.service_type = null;
    } else {
        this.newTicket.other_request_type = null;
    }
    
    try {
      const response = await this.createTicket(this.newTicket);
      this.$successNotyf(this.$t('messages.f'));
      this.newTicket.title= null,
      this.newTicket.description= null,
      this.newTicket.request_type= null,
      this.newTicket.other_request_type= null,
      this.newTicket.criticality= null
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      this.$errorNotyf(this.$t('messages.e'));
    }
  },   

  async startTicket(ticketId) {
    try {
      await this.updateTicketStatus({ ticket_id: ticketId, status: 'In Progress' });
      this.$successNotyf(this.$t('page.tickets.start_success'));
      await this.loadInitialData();
    } catch (error) {
      this.$errorNotyf(this.$t('page.tickets.start_error'));
      console.error('Error starting ticket:', error);
    }
  },

  async completeTicket(ticketId) {
    try {
      await this.updateTicketStatus({ ticket_id: ticketId, status: 'Completed' });
      this.$successNotyf(this.$t('page.tickets.complet_success'));
      await this.loadInitialData();
    } catch (error) {
      this.$errorNotyf(this.$t('page.tickets.complet_error'));
      console.error('Error completing ticket:', error);
    }
  },

  calculateDelay(ticket) {
    const now = new Date();
    const deadline = new Date(ticket.resolution_deadline);
    if (now > deadline && ticket.status !== 'Completed') {
      const delay = Math.ceil((now - deadline) / (1000 * 60 * 60 * 24)); // Convert to days
      return this.$t('errors.a') + `${delay}` + this.$t('new.days');
    }
    return this.$t('new.a');
  },

  },

  async mounted() {
    await this._currentUser();
    this.isLoading = false;
    this.loadInitialData();
  }
};
</script>
