import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {

  users_report_outils({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-user-report-outils')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error); 
        });
    });
  },

  users_reports({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-users-reports?user_type=${params.user_type}&grade=${params.grade}&department=${params.department}&country=${params.country}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error); 
        });
    });
  },
  projects_report_outils({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-project-report-outils')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error); 
        });
    });
  },

  projects_reports({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-projects-reports?service=${params.service}&leader=${params.leader}&status=${params.status}&manager=${params.manager}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error); 
        });
    });
  },

  evaluations_reports({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-evaluations-reports?start_date=${params.start_date}&end_date=${params.end_date}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error); 
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
