import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LandingPage from '@/views/landingPage.vue'
import AddProject from '@/views/forms/add-project.vue'
import EditProject from '@/views/forms/edit-project.vue'
import AddServiceTask from '@/views/forms/add-service-task.vue'
import ProjectDetail from '@/views/page/project-detail.vue'
import TaskDetail from '@/views/page/task-detail.vue'
import UsersComponent from '@/views/page/users.vue'
import UserType from '@/views/page/user-type.vue'
import Grade from '@/views/page/grade.vue'
import EntrepriseComponent from '@/views/page/entreprise_partner.vue'
import UserCalendar from '@/views/page/user-calendar.vue'
import UserPermissions from '@/views/page/permissions.vue'
import PartenersComponent from '@/views/page/parteners.vue'
import TasksComponent from '@/views/page/tasks.vue'
import ServicesComponent from '@/views/page/services.vue'
import ServiceDetail from '@/views/page/service-detail.vue'
import EditTask from '@/views/forms/edit-task-config.vue'
import LoginView from '@/views/auth/Connexion.vue'
import ResetPassword from '@/views/auth/reset-password.vue'
import ProfileUser from '@/views/auth/profil-user.vue'
import AppLayout from '@/components/Layout.vue'
import jwt_decode from 'jwt-decode'
import StatistiqueUser from '@/views/page/statistiques/user.vue'
import UserStatistic from '@/views/page/statistiques/user-detail.vue'
import StatistiqueProject from '@/views/page/statistiques/project.vue'
import StatistiqueCountry from '@/views/page/statistiques/country.vue'
import Notifications from '@/views/page/statistiques/notifications.vue'
import Settings from '@/views/auth/settings.vue'
import AuditTrail from '@/views/auth/audittrail.vue'
import Evaluation from '@/views/evaluation/evaluation.vue'
import CollaboratorEvaluation from '@/views/evaluation/my_collaborator.vue'
import HeadEvaluation from '@/views/evaluation/head_evaluation.vue'
import DeviceView from '@/views/page/device'
import TicketList from '@/views/page/tickets.vue'
import TicketDetail from '@/views/page/ticket-detail.vue'

const routes = [
  {
    path: '/',
    component: AppLayout,
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/add-project',
        name: 'add-project',
        component: AddProject,
      },
      {
        path: '/add-service/:id/task',
        name: 'add-service-task',
        component: AddServiceTask,
      },
      {
        path: '/project/:id/detail',
        name: 'project-detail',
        component: ProjectDetail,
      },
      {
        path: '/project/:id/edit',
        name: 'edit-project',
        component: EditProject,
      },
      {
        path: '/users',
        name: 'users',
        component: UsersComponent,
      },
      {
        path: '/user-type',
        name: 'user-type',
        component: UserType,
      },
      {
        path: '/grade',
        name: 'grade',
        component: Grade,
      },
      {
        path: '/entreprise',
        name: 'entreprise',
        component: EntrepriseComponent,
      },
      {
        path: '/user/:id/calendar',
        name: 'user-calendar',
        component: UserCalendar,
      },
      {
        path: '/user/:id/permissions',
        name: 'user-permissions',
        component: UserPermissions,
      },
      {
        path: '/parteners',
        name: 'parteners',
        component: PartenersComponent,
      },
      {
        path: '/services',
        name: 'services',
        component: ServicesComponent,
      },
      {
        path: '/service/:id/detail',
        name: 'service-detail',
        component: ServiceDetail,
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: TasksComponent,
      },
      {
        path: '/task/:id/detail',
        name: 'task-detail',
        component: TaskDetail,
      },
      {
        path: '/edit/:id/task',
        name: 'edit-task-config',
        component: EditTask,
      },
      {
        path: '/profile-user',
        name: 'profile-user',
        component: ProfileUser,
      },
      {
        path: '/statistique-user',
        name: 'statistique-user',
        component: StatistiqueUser,
      },
      {
        path: '/user-statistic/:id',
        name: 'user-statistic',
        component: UserStatistic,
      },
      {
        path: '/statistique-project',
        name: 'statistique-project',
        component: StatistiqueProject,
      },
      {
        path: '/statistique-country',
        name: 'statistique-country',
        component: StatistiqueCountry,
      },
      {
        path: '/notifications/:id',
        name: 'notifications',
        component: Notifications,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/audit-trail',
        name: 'audittrail',
        component: AuditTrail,
      },
      {
        path: '/evaluation',
        name: 'evaluation',
        component: Evaluation,
      },
      {
        path: '/collaborator-evaluation',
        name: 'collaborator-evaluation',
        component: CollaboratorEvaluation,
      },
      {
        path: '/head-evaluation',
        name: 'head-evaluation',
        component: HeadEvaluation,
      },
      {
        path: '/devices',
        name: 'devices',
        component: DeviceView,
      },
      {
        path: '/tickets',
        name: 'tickets',
        component: TicketList,
      },
      {
        path: '/ticket/:ticket_id',
        name: 'ticket-detail',
        component: TicketDetail,
        props: route => ({ ticket_id: route.params.ticket_id })
      },
      {
        path: '/departments',
        name: 'departments',
        component: () => import('../views/page/departments.vue')
      },
      {
        path: '/users-reports',
        name: 'rapport-user',
        component: () => import('../views/page/users-rapport.vue')
      },
      {
        path: '/projects-reports',
        name: 'rapport-project',
        component: () => import('../views/page/projects-rapport.vue')
      },

      {
        path: '/get-list-evaluations',
        name: 'rapport-evaluation',
        component: () => import('../views/page/evaluation-rapport.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/landing',
    name: 'landing',
    component: LandingPage,
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const isTokenValid = () => {
  const token = localStorage.getItem('access_token')
  if (!token) {
    return false
  }

  const decoded = jwt_decode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isTokenValid()) {
    window.notyf.open({
      type: 'warning',
      message: 'Please log in',
      dismissible: true
    });
    return next('/login')
  }
  return next()
})

export default router
